<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">Akt Sverkalar ruyhati</h3>
              </div>
              <div class="card-button">
                <create />
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="getAktSverka"
                :items-per-page="10"
                hide-default-footer
                class="row-pointer"
                single-select
                @click:row="rowClick"
                item-key="id"
              >
                <template v-slot:[`item.index`]="{ item }">
                  {{
                    getAktSverka
                      .map(function (x) {
                        return x.id
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div style="text-align: right !important">
                    <action :index="item"></action>
                  </div> </template
              ></v-data-table>
            </div>
            <div class="text-left my-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="7"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getProvidersCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './action'
import create from './create'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action, create },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10,
      currentPage: 1,
      headers: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Mijoz nomi',
          value: 'client_name'
        },
        {
          text: 'Hisobot sanasi',
          value: 'report_date'
        },
        {
          text: 'Hisobot boshi',
          value: 'report_start_date'
        },
        {
          text: 'Hisobot oxiri',
          value: 'report_end_date'
        },
        {
          text: 'BTS debet saldo boshida',
          value: 'debet_start_saldo_company'
        },
        {
          text: 'BTS kredit saldo boshida',
          value: 'credit_start_saldo_company'
        },
        {
          text: 'Mijoz debet saldo boshida',
          value: 'debet_start_saldo_client'
        },
        {
          text: 'Mijoz kredit saldo boshida',
          value: 'credit_start_saldo_client'
        },
        {
          text: 'BTS debet saldo oxirida',
          value: 'debet_end_saldo_company'
        },
        {
          text: 'BTS kredit saldo oxiri',
          value: 'credit_end_saldo_company'
        },
        {
          text: 'Mijoz debet saldo oxiri',
          value: 'debet_end_saldo_client'
        },
        {
          text: 'Mijoz kredit saldo oxiri',
          value: 'credit_end_saldo_client'
        },
        {
          text: 'Kontrakt raqam',
          value: 'contract_number'
        },
        {
          text: 'Yaratilgan sana',
          value: 'created_at'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    }
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  computed: {
    getAktSverka() {
      return this.$store.getters.providerContractAktSverka.results
    },
    getProvidersCount() {
      return this.$store.state.requests.providerContractAktSverka
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push(
          '/providers/contract/aktsverka/details/' + item.id + '/'
        )
        row.select(false)
      }
    },
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,

          query: { page: value }
        })
      }
      this.$store.dispatch('providerContractAktSverkaPag', value)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Postavshiklar' },
      { title: 'Akt sverka' }
    ])
  }
}
</script>
